import type { DefaultPageProps } from '~/components/page'
import { DefaultPage } from '~/components/page'
import { pageQuery } from '~/queries'
import type { GetStaticProps, Page } from '~/types'
import { REVALIDATE_TIME, getClient } from '~/utils'

export const getStaticProps: GetStaticProps<DefaultPageProps> = async ({
	params = { slug: '/' },
	preview = null,
	previewData = null
}) => {
	const pageFilter = /* groq */ `slug.current == "/" && category == null`
	const page = await getClient(preview, previewData).fetch<Page[]>(pageQuery(pageFilter), {
		...params,
		category: params.category ?? null
	})
	return page
		? { props: { page, pageFilter, preview, params, previewData }, revalidate: REVALIDATE_TIME }
		: { props: { params }, notFound: true, revalidate: REVALIDATE_TIME }
}

export default DefaultPage
